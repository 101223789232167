import {
  faGuitarElectric,
  faGuitar,
  faDrum,
  faCommentMusic,
  faPlus,
  faBanjo,
  faAmpGuitar,
  faMusicNote,
  faViolin,
  faPiano,
  faPianoKeyboard,
  faFlute,
  faClarinet,
  faGuitars,
  faMicrophone,
  faMicrophoneLines,
  faMicrophoneStand,
  faMusic,
  faSaxophone,
  faTrumpet,
  faMandolin,
  faKazoo,
  faDrumSteelpan,
  faTriangleInstrument,
  faMessageMusic,
  faCalendarLinesPen,
} from "@fortawesome/pro-duotone-svg-icons";

const DuoToneIcons = {
  init: (library: any) => {
    /* add icons to the library */
    library.add(
      faGuitarElectric,
      faGuitar,
      faDrum,
      faCommentMusic,
      faPlus,
      faBanjo,
      faAmpGuitar,
      faMusicNote,
      faViolin,
      faPiano,
      faPianoKeyboard,
      faFlute,
      faClarinet,
      faGuitars,
      faMicrophone,
      faMicrophoneLines,
      faMicrophoneStand,
      faMusic,
      faSaxophone,
      faTrumpet,
      faMandolin,
      faKazoo,
      faDrumSteelpan,
      faTriangleInstrument,
      faMessageMusic,
      faCalendarLinesPen
    );
  },
};

export default DuoToneIcons;


import {
    useIonRouter,
    menuController,

} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import MenuItem from "./MenuItem.vue";
export default defineComponent({
    components: {
        MenuItem,
    },
    props: ["menuItems"],
    setup() {
        const ionRoute = useIonRouter();
        const selectedSubMenu = ref(null as any);
        const opened = ref([]);
        const menuClick = async (item: any) => {
            const currentSubMenu = selectedSubMenu.value?.label;
            selectedSubMenu.value = null;

            if (item.link) {
                if (item.nav == 'replace') {
                    ionRoute.replace(item.link);
                } else {
                    ionRoute.push(item.link);
                }
                menuController.close()
            } else if (item.submenu) {
                if (currentSubMenu != item.label) {
                    selectedSubMenu.value = item;
                }
            } else if (item.click) {
                item.click();
            }
        }


        return {
            selectedSubMenu,
            menuClick,
            opened,
        };
    },
});

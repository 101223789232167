import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fa121422"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-flow-column left-menu-container" }
const _hoisted_2 = { class: "flex-flow-start" }
const _hoisted_3 = { class: "flex-flow-body" }
const _hoisted_4 = { class: "flex-flow-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_MenuList = _resolveComponent("MenuList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_list_item, {
            title: "Soundhaus",
            class: "menu-header"
          }, {
            prepend: _withCtx(() => [
              _createVNode(_component_app_icon, { class: "menu-app-icon" })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menu) => {
        return (_openBlock(), _createBlock(_component_MenuList, {
          menuItems: menu,
          key: menu.id
        }, null, 8, ["menuItems"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_MenuList, { menuItems: _ctx.bottomMenu }, null, 8, ["menuItems"])
    ])
  ]))
}
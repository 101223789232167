import ApiService from "./api.service";
import { TokenService } from "./token.service";
import { ResponseError } from "./tripoli.service";
import qs from "qs";
import UAParser from "ua-parser-js";

class AuthenticationError extends Error {
  errorCode: any;
  constructor(errorCode: any, message: string | undefined) {
    super(message);
    this.name = this.constructor.name;
    if (message != null) {
      this.message = message;
    }
    this.errorCode = errorCode;
  }
}

const AuthService = {
  signIn: async function (signInData: any) {
    const uap = new UAParser();
    const browserInfo = uap.getResult();
    const guestId = TokenService.getGuestToken() || "";
    TokenService.removeToken();
    console.log(browserInfo);
    const data = JSON.stringify({
      grant_type: "password",
      email: signInData.username,
      password: signInData.password,
      browserInfo: JSON.stringify(browserInfo),
      guestId: guestId,
    });
    const requestData = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic " +
          btoa(
            process.env.VUE_APP_CLIENT_ID +
              ":" +
              process.env.VUE_APP_CLIENT_SECRET
          ),
      },
      body: data,
    };

    try {
      const response = await ApiService.customRequest("/v1/auth/", requestData);
      await TokenService.save(response);
      return response;
    } catch (error) {
      this.catchError(error);
    }
  },
  generateGuestToken: async function () {
    const uap = new UAParser();
    const browserInfo = uap.getResult();
    const requestData = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic " +
          btoa(
            process.env.VUE_APP_CLIENT_ID +
              ":" +
              process.env.VUE_APP_CLIENT_SECRET
          ),
      },
      body: qs.stringify({
        browserInfo: browserInfo,
      }),
    };

    try {
      const response = await ApiService.customRequest(
        "/v1/auth/guest-token",
        requestData
      );
      if (response && response.success) {
        TokenService.saveGuestToken(response.payload);
      } else {
        console.error("Create guest token failed. No token returned");
      }
    } catch (error: any) {
      console.error("Create guest token failed" + error.toString());
    }
  },
  refreshToken: async function () {
    const refreshToken = await TokenService.getRefreshToken();
    const requestData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic " +
          btoa(
            process.env.VUE_APP_CLIENT_ID +
              ":" +
              process.env.VUE_APP_CLIENT_SECRET
          ),
      },
      body: qs.stringify({
        token: refreshToken,
      }),
    };

    try {
      const response = await ApiService.customRequest(
        "/v1/auth/refresh",
        requestData
      );
      if (response && response.success) {
        await TokenService.save(response);
        return response.access_token;
      } else {
        throw new AuthenticationError(0, "Refresh Token failed");
      }
    } catch (error: any) {
      throw new AuthenticationError(
        error.response.status,
        error.response.data.error_description
      );
    }
  },

  profile: async function (id: any) {
    try {
      return await ApiService.post(`/User/Profile/${id}`, {});
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },

  signOut: async () => {
    await TokenService.removeAll();
  },

  // signup: async function (email: any, password: any, name: any) {
  //   const guestId = TokenService.getGuestToken() || "";

  //   const signupData = {
  //     method: "post",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //       email: email,
  //       password: password,
  //       name: name,
  //       guestId: guestId,
  //     }),
  //   };

  //   try {
  //     return await ApiService.customRequest("/api/v1/user", signupData);
  //   } catch (error) {
  //     this.catchError(error);
  //   }
  // },
  catchError: function (error: any) {
    let status;
    let description;

    if (error.response === undefined) {
      status = error.message;
      description = error.message;
    } else {
      status = error.response.status;
      description = error.response.data.error_description;
    }

    throw new AuthenticationError(status, description);
  },
};
export { AuthService, AuthenticationError };

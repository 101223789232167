//import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
//import { useErrorStore } from "@/store/error.store";
import { TokenService } from "@/services/token.service";
import qs from "qs";
const app_version = process.env.VUE_APP_CLIENT_VERSION;
export class HttpError extends Error {
  constructor(message?: string) {
    super(message); // 'Error' breaks prototype chain here
    this.name = "HttpError";
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}

const ApiService = {
  _requestInterceptor: 0,
  _401interceptor: 0,
  _responseInterceptors: [] as any,
  _errorInterceptors: [] as any,
  _tokenFn: () => TokenService.getToken(),
  _guestTokenFn: () => TokenService.getGuestToken(),
  baseUrl: "",
  appVersion: "0",
  _handleFetchResponse: async function (response: Response) {
    this._responseInterceptors.forEach((element: any) => {
      element(response);
    });

    if (response.ok) return response.json();

    this._errorInterceptors.forEach((element: any) => {
      element(response);
    });
    throw new Error(response.statusText);
  },
  _handleError(error: any, reject: any) {
    console.log(error);
    reject(error);
  },
  overrideTokenFn(fn: () => string, guestFn: () => string) {
    this._tokenFn = fn;
    this._guestTokenFn = guestFn;
  },
  makeUrl(url: string) {
    return encodeURI(this.baseUrl + url);
  },
  getDefault(headers: any) {
    const guestId = this._guestTokenFn();
    if (guestId) {
      headers = {
        ...headers,
        "X-Guest-Id": guestId,
      };
    }
    const ret = {
      headers: {
        "X-App-Version": app_version,
        ...headers,
      },
    };
    const token = this._tokenFn();
    if (token) {
      ret.headers.Authorization = `Bearer ${token}`;
    }

    return ret;
  },
  getOptions() {
    return {
      ...this.getDefault({}),
      method: "GET",
    };
  },
  postFormOptions(data?: any) {
    return {
      ...this.getDefault({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
      method: "POST",
      body: qs.stringify(data),
    };
  },
  postOptions(data?: any) {
    return {
      ...this.getDefault({ "Content-Type": "application/json" }),
      method: "POST",
      body: JSON.stringify(data),
    };
  },
  init(baseURL: string | undefined, appVersion: string | undefined) {
    if (baseURL) {
      this.baseUrl = baseURL;
    }

    if (appVersion) {
      this.appVersion = appVersion;
    }

    // const authStore = useAuthStore();
    // const errorStore = useErrorStore();

    //axios.defaults.baseURL = baseURL;
    /*   axios.interceptors.response.use(this.responseHandler, async (error) => {
      this._errorInterceptors.forEach((element: any) => {
        element(error);
      });

      if (error.request.status === 401) {
        if (error.config.url.includes("oauth/token")) {
          await authStore.signOut();
          throw error;
        } else {
        
        }
      } else {
        console.log(error);
        errorStore.setAxiosError(error);
        throw error;
      }
    }); */
  },
  /*
  responseHandler(response: AxiosResponse<any>) {
    const config = response?.config;
    if (config.raw) {
      return response;
    }
    if (response.status == 200) {
      const data = response?.data;
      if (!data) {
        throw new HttpError("API Error. No data!");
      }
      return response;
    }
    throw new HttpError("API Error! Invalid status code!");
  },

  responseErrorHandler(response: AxiosResponse<any>) {
    const config = response?.config;
    if (config.raw) {
      return response;
    }
    // the code of this function was written in above section.
    return this.httpErrorHandler(response);
  },
  httpErrorHandler(error: any) {
    if (error === null) throw new Error("Unrecoverable error!! Error is null!");
    if (axios.isAxiosError(error)) {
      //here we have a type guard check, error inside this if will be treated as AxiosError
      const response = error?.response;
      const request = error?.request;
      //const config = error?.config //here we have access the config used to make the api call (we can make a retry using this conf)

      if (error.code === "ERR_NETWORK") {
        console.log("connection problems..");
      } else if (error.code === "ERR_CANCELED") {
        console.log("connection canceled..");
      }
      if (response) {
        //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
        const statusCode = response?.status;
        if (statusCode === 404) {
          console.log(
            "The requested resource does not exist or has been deleted"
          );
        } else if (statusCode === 401) {
          console.log("Please login to access this resource");
          //redirect user to login
        }
      } else if (request) {
        //The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js
      }
    }
    //Something happened in setting up the request and triggered an Error
    console.log(error.message);
  },
  */

  /*
  get(resource: string) {
    return axios.get(resource);
  },*/

  get<T>(resource: string) {
    return new Promise<T>((resolve, reject) => {
      fetch(this.makeUrl(resource), this.getOptions())
        .then((response) => this._handleFetchResponse(response))
        .then((data) => {
          resolve(data as T);
        })
        .catch((error) => {
          this._handleError(error, reject);
        });
    });
  },

  postForm<T>(resource: string, data: any) {
    return new Promise<T>((resolve, reject) => {
      fetch(this.makeUrl(resource), this.postFormOptions(data))
        .then((response) => this._handleFetchResponse(response))
        .then((data) => {
          resolve(data as T);
        })
        .catch((error) => reject(error));
    });
  },
  postJson<T>(resource: string, data: any) {
    return new Promise<T>((resolve, reject) => {
      fetch(this.makeUrl(resource), this.postOptions(data))
        .then((response) => this._handleFetchResponse(response))
        .then((data) => {
          resolve(data as T);
        })
        .catch((error) => reject(error));
    });
  },
  customRequest(url: string, options: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      fetch(this.makeUrl(url), {
        ...this.getDefault({}),
        ...options,
      })
        .then((response) => this._handleFetchResponse(response))
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  post(resource: string, data: any) {
    return this.postJson<any>(resource, data);
  },

  /*
  customRequest(data: AxiosRequestConfig) {
    return axios(data);
  },
  */

  addResponseInterceptor(callback: any) {
    this._responseInterceptors.push(callback);
  },

  addErrorInterceptor(callback: any) {
    this._errorInterceptors.push(callback);
  },
};

export default ApiService;

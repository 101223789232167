import ApiService from "./api.service";
import { TokenService } from "./token.service";
import { toastController } from "@ionic/vue";
import { useAuthStore } from "@/store/auth.store";
import { alertCircleSharp } from "ionicons/icons";
import { Router } from "vue-router";
const setup = (router: Router) => {
  const refreshToken = TokenService.getRefreshToken();
  const token = TokenService.getToken();
  const authStore = useAuthStore();
  const sessionExpired = () => {
    TokenService.removeAll();
    //    authNav.navToSignIn(router.currentRoute.value.fullPath);
    router.replace(
      "/?signOut=true&errMsg=Your Session has expired. Please sign in again."
    );
  };
  const refreshSuccess = (result: any) => {
    if (result && result && result.success) {
      TokenService.save(result);
      router.go(0);
    } else {
      sessionExpired();
    }
  };
  const refreshAccessToken = async (callback: any) => {
    authStore.setRefreshingToken(true);
    const refreshToken = TokenService.getRefreshToken();
    ApiService.post("/v1/auth/refresh/", { token: refreshToken })
      .then((result: any) => {
        callback(result);
      })
      .catch(() => {
        TokenService.removeAll();
        authStore.setAuthChecked();
        sessionExpired();
      })
      .finally(() => {
        authStore.setRefreshingToken(false);
      });
  };
  ApiService.addErrorInterceptor(async (response: Response) => {
    if (response && response.status) {
      switch (response.status) {
        case 401: {
          // token expired
          const body = await response.text();
          if (body == "TOKEN-EXPIRED") {
            await refreshAccessToken(refreshSuccess);
            return;
          }
          sessionExpired();
          return;
        }
      }
    }
    const body = await response.text();
    const msg = body ? (body as string) : response.statusText;
    const toast = await toastController.create({
      message: msg,
      header: "Server Error",
      duration: 4000,
      cssClass: "global-error-toast",
      icon: alertCircleSharp,
      buttons: [
        {
          text: "Dismiss",
          role: "cancel",
        },
      ],
    });
    await toast.present();
    await toast.onDidDismiss();
  });

  return new Promise((resolve) => {
    if (token && refreshToken) {
      console.log("refreshing access token");
      refreshAccessToken((result: any) => {
        if (result && result.success) {
          console.log("token refreshed");
          TokenService.save(result);
          authStore.setAuthChecked();
          resolve(true);
        } else {
          console.log("refresh token failed");
          TokenService.removeAll();
          authStore.setAuthChecked();
          sessionExpired();
          resolve(false);
        }
      });
    } else {
      console.log("no token");
      authStore.setAuthChecked();
      resolve(false);
    }
  });
};
export default setup;

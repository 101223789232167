import { server } from "ionicons/icons";
import { defineStore } from "pinia";
import { ref, computed } from "vue";
export const useClientVersionStore = defineStore("ClientVersion", () => {
  const serverVersion = ref("");

  const showNewVersionAvailable = computed(() => {
    return serverVersion.value;
  });

  const setLatestClientVersion = (value: string) => {
    if (serverVersion.value !== value) {
      serverVersion.value = value;
    }
  };

  const latestVersion = computed(() => serverVersion.value);

  return {
    showNewVersionAvailable,
    setLatestClientVersion,
    latestVersion,
  };
});

import "@mdi/font/css/materialdesignicons.css";
//import "vuetify/styles";
import "./main.scss";
// Composables
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
//import * as directives from "vuetify/directives";
import theme from "./theme";
/*
import {
  VDataTable,
  VDataTableServer,
  VDataTableVirtual,
} from "vuetify/labs/VDataTable";
*/
// import { VSkeletonLoader } from "vuetify/labs/VSkeletonLoader";
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components: {
    ...components,
  },
  //directives,
  theme,
});

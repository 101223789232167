import ApiService from "./api.service";
import { postFormPromise } from "./base.service";
import { InstrumentName } from "@/types/instrument-name";
import { RequestResult } from "@/types/request-result";
import { GroupDetails } from "@/types/group-details";
import { UserGroupProfile } from "@/types/user-group-profile";
import NetworkBackedCache from "@/utils/network-backed-cache";

const GroupRoot = "/v1/groups";

const SessionGroupEndpoint = {
  Details: `${GroupRoot}/details/`,
};

class ResponseError extends Error {
  errorCode: any;
  errorMessage: any;
  constructor(errorCode: any, message: string | undefined) {
    super(message);
    this.name = this.constructor.name;
    if (message != null) {
      this.message = message;
    }
    this.errorCode = errorCode;
  }
}

const _detailCache = NetworkBackedCache<GroupDetails>("", {
  memCacheExpiresMs: 60000 * 60 * 60, // 60 minutes;
  fetchSingleUrl: SessionGroupEndpoint.Details,
  fetchSingleMethod: "get",
});

const TripoliService = {
  clearGroupDetailCache: async function (groupId: any) {
    await _detailCache.del(groupId);
  },
  allInstruments: async function (
    sessionId: any
  ): Promise<RequestResult<Array<InstrumentName>>> {
    return postFormPromise<RequestResult<Array<InstrumentName>>>(
      `/Session/AllInstrumentsForSession/?sessionId=${sessionId}`,
      null
    );
  },
  // setSlotCore: async function (slotId: any, sessionId: any, core: any) {
  //   return postFormPromise(
  //     `/SessionSong/SetSlotCore/?sessionId=${sessionId}&instrumentSlotId=${slotId}&isCore=${core}`,
  //     null
  //   );
  // },
  findGroup: async function (coordinates: any) {
    try {
      return ApiService.get<any>("/Public/Search?coordinates=" + coordinates);
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  search: async function (city: any) {
    try {
      return ApiService.get<any>("/Public/SearchCity?q=" + city);
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  deleteSession: async function (groupId: number, sessionId: any) {
    try {
      return ApiService.post(
        "/Session/DeleteSession?groupId=" + groupId + "&sessionId=" + sessionId,
        {}
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },

  searchSong: async function (title: string) {
    try {
      return ApiService.get<any>("/Songs/Search?title=" + title);
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },

  loadGroup: async function (groupId: any) {
    try {
      return _detailCache.get(groupId);
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  sessions: async function (groupId: any) {
    try {
      return ApiService.get<any>(
        "/SessionGroup/ListSessionsForGroup?groupId=" + groupId
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  states: async function () {
    try {
      return ApiService.get<any>("/Public/StatesForCountry/1");
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  locations: async function (groupId: any) {
    try {
      return ApiService.get<any>("/SessionGroup/Locations?groupId=" + groupId);
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  instruments: async function (groupId: any) {
    try {
      return ApiService.get<any>("/public/AllInstruments/" + groupId);
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  partsForSong: async function (sessionSongId: number) {
    try {
      return ApiService.postJson<any>("/v1/song-admin/slots-for-song/", {
        sessionSongId: sessionSongId,
      });
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },

  addNewUser: async function (
    groupId: any,
    first: string,
    last: string,
    email: string,
    gender: any
  ) {
    try {
      return ApiService.postForm<any>("/SessionGroup/AddUser/", {
        GroupId: groupId,
        FirstName: first,
        LastName: last,
        Email: email,
        Gender: gender,
      });
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },

  spotifySearch: async function (title: any, artist: any) {
    try {
      return ApiService.postJson<any>(`/Spotify/Search`, { title, artist });
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },

  usersForGroup: async function (
    groupId: any
  ): Promise<RequestResult<Array<UserGroupProfile>>> {
    try {
      return ApiService.get<RequestResult<Array<UserGroupProfile>>>(
        `/SessionGroup/Users/${groupId}`
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
};

export { TripoliService, ResponseError };

import { UserNotificationDetailed } from "@/types/user-notification-detailed";
import {
  postFormPromise,
  postJsonPromise,
  postJsonRequestResult,
} from "./base.service";
import { RequestResult } from "@/types/request-result";
import { MessageThread } from "@/types/message-thread";
import { ChannelMessage } from "@/types/client/channel-message";
import { TreeNode } from "@/types/client/tree-node";
const Endpoint = {
  thread: "/v1/messages/get-thread",
  userFeed: "/v1/messages/user-feed",
  channelFeed: "/v1/messages/channel-feed",
  setReaction: "/v1/messages/set-reaction",
};
const MessageService = {
  startDm: async function (withUser: any) {
    return postJsonPromise(`/Message/StartDirectChat?toUserId=${withUser}`, {});
  },
  setReaction: async function (
    messageId: number,
    reactionType: number,
    add: boolean
  ) {
    return postJsonPromise(Endpoint.setReaction, {
      messageId,
      reactionType,
      add,
    });
  },

  getChannelFeed: async function (
    channelId: number,
    lastReadMsg: any,
    take: number
  ) {
    return postJsonPromise<Array<TreeNode<number, ChannelMessage>>>(
      Endpoint.channelFeed,
      {
        channelId,
        lastReadMsg,
        take,
      }
    );
  },
  newestMessages: async function (
    channelId: any,
    lastReadMsg: any,
    take: number
  ) {
    return postFormPromise(
      `/Message/NewestMessages?channelId=${channelId}&lastReadMessage=${lastReadMsg}&take=${take}`,
      {}
    );
  },
  olderMessages: async function (
    channelId: any,
    lastReadMsg: any,
    take: number
  ) {
    return postFormPromise(
      `/Message/OlderMessages?channelId=${channelId}&lastReadMessage=${lastReadMsg}&take=${take}`,
      {}
    );
  },
  getDirectMessageGroups: async function () {
    return postFormPromise(`/Message/GetDirectMessageGroups`, {});
  },
  postsForChannel: async function (
    channelId: any,
    pageNo: number,
    take: number
  ) {
    return postJsonPromise(
      `/Message/Posts/${channelId}?page=${pageNo}&take=${take}`,
      {}
    );
  },
  adminMessagesForGroup: async function (groupId: any) {
    return postJsonPromise(`/Message/AdminGroupMessages/${groupId}`, {});
  },
  adminDeleteMessage: async function (groupId: any, msgId: any) {
    return postJsonPromise(
      `/Message/AdminDeleteMessage/${groupId}?id=${msgId}`,
      {}
    );
  },
  messagesForGroup: async function (
    groupId: any,
    start: number,
    limit: number
  ) {
    return postJsonPromise(`/Message/GroupFeed/${groupId}`, { start, limit });
  },
  userFeed: async function (lastFetchedId = 0) {
    return postJsonPromise<RequestResult<UserNotificationDetailed[]>>(
      Endpoint.userFeed,
      { lastFetchedId }
    );
  },
  markAllNotificationsRead: async function () {
    return postJsonPromise(`/Message/MarkAllNotificationsRead/`, {});
  },
  markShown: async function (userNotificationId: number) {
    return postJsonPromise(
      `/Message/MarkPopupShown/?userNotificationId=${userNotificationId}`,
      {}
    );
  },
  getMessageThread: async function (
    messageId: any,
    channelId: any,
    lastId: number,
    perPage: number
  ): Promise<MessageThread> {
    return postJsonPromise<MessageThread>(Endpoint.thread, {
      messageId,
      channelId,
      lastId,
      perPage,
    });
  },
  findChildInThread: async function (
    messageId: any,
    channelId: any,
    childMsgId: number
  ): Promise<RequestResult<MessageThread>> {
    return postJsonPromise(
      `/Message/FindChildInThread/${messageId}?channelId=${channelId}&childMsgId=${childMsgId}`,
      {}
    );
  },
  updateMessageBody: async function (
    editMessageId: any,
    messageId: any,
    msg: string
  ) {
    return postJsonPromise(`/Message/EditMsgBody/${editMessageId}`, {
      key: messageId,
      value: msg,
    });
  },
  deleteMessage: async function (channelId: any, messageId: any) {
    return postJsonPromise(
      `/Message/DeleteMessage/${messageId}?channelId=${channelId}`,
      {}
    );
  },

  replyToGroupMessage: async function (
    channelId: any,
    messageId: any,
    msg: string
  ) {
    return postJsonPromise(`/Message/ReplyToMessageGroup/${channelId}`, {
      key: messageId,
      value: msg,
    });
  },
  sendDirectMessage: async function (
    channelId: any,
    subject: any,
    body: any,
    thumbnail: any,
    parentMessageId: any
  ) {
    return postJsonPromise(`/Message/SendDm?channelId=${channelId}`, {
      subject,
      body,
      thumbnail,
      parentMessageId,
    });
  },
  startMessageForSong: async function (
    channelId: any,
    sessionId: any,
    sessionSongId: any,
    body: any
  ) {
    return postJsonPromise(
      `/Message/StartMessageForSong?channelId=${channelId}&sessionSongId=${sessionSongId}&sessionId=${sessionId}`,
      {
        subject: null,
        body,
        thumbnail: null,
        parentMessageId: null,
      }
    );
  },
  sendMessage: async function (
    channelId: any,
    subject: any,
    body: any,
    thumbnail: any,
    parentMessageId: any
  ) {
    return postJsonPromise(`/Message/SendMessage?channelId=${channelId}`, {
      subject,
      body,
      thumbnail,
      parentMessageId,
    });
  },
  editMessage: async function (
    id: any,
    subject: any,
    body: any,
    thumbnail: any,
    parentMessageId: any
  ) {
    return postJsonPromise(`/Message/EditMessage?id=${id}`, {
      id,
      subject,
      body,
      thumbnail,
      parentMessageId,
    });
  },
};
export { MessageService };


import {
    IonList,
    IonItemGroup,
    IonItemDivider,
} from "@ionic/vue";
import { defineComponent, onMounted, computed } from "vue";
import UserNotificationPreview from "./UserNotificationPreview.vue";
import { MessageService } from "@/services/message.service";
import { useAuthStore } from "@/store/auth.store";
import { useRoute } from "vue-router";
export default defineComponent({
    components: {
        IonList,
        IonItemGroup,
        IonItemDivider,
        UserNotificationPreview,
    },
    props: ["items"],
    setup(props) {
        const route = useRoute();
        const markAllInParams = computed(() => route.query.markAllRead == "true");
        const authStore = useAuthStore();
        const newItems = computed(() => {
            return props.items.filter((x: any) => !x.notification.received);
        });
        const olderItems = computed(() => {
            return props.items.filter((x: any) => x.notification.received);
        });
        const markAllRead = async () => {
            MessageService.markAllNotificationsRead().then(() => {
                authStore.refreshNotifications();
            });
        }
        onMounted(async () => {
            if (markAllInParams.value) {
                await markAllRead();
            }
        });

        return {
            markAllRead,
            newItems,
            olderItems,
        };
    },
});


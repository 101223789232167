
import {
    logOutOutline,
    personCircleSharp

} from "ionicons/icons";
import {
    useIonRouter,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useAuthStore } from "@/store/auth.store";
import { useAuthNav } from "@/composables/useAuthNav";
export default defineComponent({
    components: {},
    setup() {
        const authStore = useAuthStore();
        const ionRouter = useIonRouter();
        const logout = () => {
            useAuthNav().navToSignIn("/");
            //ionRouter.replace("/sign-in");
        }
        return {
            logOutOutline,
            logout,
            personCircleSharp,
        };
    },
});

import { useAuthStore } from "@/store/auth.store";
import { ref, computed, watch } from "vue";
import { MessageService } from "@/services/message.service";
import { useTabActive } from "./useTabActive";
const activeItem = ref({} as any);
const endTimer = ref(0);
const startTimer = ref(0);

export const userNotificationPopup = () => {
  const authStore = useAuthStore();
  const activeTab = useTabActive();
  const notifications = computed(() => authStore.notifications);
  const newItems = computed(() => {
    return notifications.value?.filter((x: any) => !x.notification.received);
  });

  watch(
    activeTab.visible,
    async () => {
      if (activeTab.visible.value) {
        checkForNewNotification();
      }
    },
    { deep: true }
  );
  const hideNotification = () => {
    authStore.setPopupNotification(null);
  };
  const showNewItem = async () => {
    if (!activeTab.visible.value) return;
    if (activeItem.value != null) {
      await MessageService.markShown(activeItem.value.notification.id);
      startTimer.value = setTimeout(() => {
        authStore.setPopupNotification(activeItem.value);
        clearTimeout(endTimer.value);
        endTimer.value = setTimeout(() => {
          authStore.setPopupNotification(null);
        }, 10000);
      }, 500);
    }
  };
  const checkForNewNotification = async () => {
    activeItem.value = null;
    newItems.value?.forEach((x: any) => {
      if (!x.notification.isPopupShown) {
        activeItem.value = x;
        return;
      }
    });
    clearTimeout(startTimer.value);
    await showNewItem();
  };
  watch(
    newItems,
    async () => {
      checkForNewNotification();
    },
    { deep: true }
  );

  return { hideNotification };
};

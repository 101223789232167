import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item, {
        button: "",
        lines: "none",
        slot: "start",
        "router-link": "/profile"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: _ctx.personCircleSharp
          }, null, 8, ["icon"]),
          _createTextVNode(" Profile ")
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, {
        button: "",
        lines: "none",
        onClick: _ctx.logout
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            icon: _ctx.logOutOutline,
            slot: "start"
          }, null, 8, ["icon"]),
          _createTextVNode(" Sign Out ")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}
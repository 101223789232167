import { useIonRouter } from "@ionic/vue";
export const useAuthNav = () => {
  const router = useIonRouter();

  const navToSignUp = (returnUrl: string) => {
    const url = "/sign-up?returnUrl=" + encodeURI(returnUrl);
    router.push(url);
  };
  const navToSignIn = (returnUrl: string) => {
    nav(returnUrl, "?signOut=true", null);
  };
  const nav = (
    returnUrl: string | undefined,
    params: string | undefined | null,
    hash: string | undefined | null
  ) => {
    let url = `/`;
    if (hash) {
      url += "#" + hash;
    }
    if (params) {
      url += params;
    }
    if (returnUrl) {
      url = url + `&redirect=${encodeURI(returnUrl) || "/"}`;
    }
    router.push(url);
  };

  return {
    navToSignUp,
    navToSignIn,
  };
};

import emitter from "tiny-emitter/instance";

const eventBus = {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args),
};
const events = {
  SCROLL_TO: "scroll-to",
  SCROLL_TO_TOP: "scroll-to-top",
  SCROLL_TO_BOTTOM: "scroll-to-bottom",
  REFRESH_SONG_LIST: "refresh-song-list",
  SONG_SLOT_UPDATED: "song-slot-updated",
  NAV_AWAY: "nav_away",
  SET_DEFAULT_MENU: "set-default-menu",
};
export { eventBus, events };

import ApiService from "./api.service";
import { ResponseError } from "./tripoli.service";
import { postFormPromise, postJsonPromise } from "./base.service";
import { RequestResult } from "@/types/request-result";
import { UpcomingSession } from "@/types/upcoming-session";
import { TokenService } from "./token.service";
const UserEndpoint = {
  sendPasswordReset: "/v1/auth/password-reset",
  register: "/v1/user/register",
  setProfileDetails: "/v1/user/update-details/",
  updateProfile: "/v1/user/update-profile/",
  updateInstrument: "/v1/user/update-instrument/",
  updateProfilePicture: "/User/UpdateProfilePicture/",
  accountRegistrationComplete: "/v1/user/account-registration-complete/",
};

const UserService = {
  addInstrument: async function (instrument: any) {
    return postJsonPromise(UserEndpoint.updateInstrument, instrument);
  },
  registrationComplete: async function () {
    return postJsonPromise(UserEndpoint.accountRegistrationComplete, {});
  },
  removeInstrument: async function (instrumentId: any) {
    return postFormPromise("/User/RemoveInstrument/" + instrumentId, {});
  },
  notifications: async function (lastId: number) {
    return postFormPromise(
      "/Message/Notifications/?lastFetchedId=" + lastId,
      {}
    );
  },

  submitProfileDetails: async function (profile: any) {
    return postJsonPromise(UserEndpoint.setProfileDetails, profile);
  },
  sendOnlineStatus: async function (action: string) {
    return postFormPromise("/User/UpdateOnlineActivity?action=" + action, {});
  },
  UserOnlineStatus: async function (id: any) {
    return postFormPromise("/User/UserOnlineStatus/" + id, {});
  },

  register: async function (email: string, password: string) {
    try {
      const guestId = TokenService.getGuestToken() || "";

      return ApiService.postJson<any>(UserEndpoint.register, {
        FirstName: "New",
        LastName: "User",
        Email: email,
        Password: password,
        GuestId: guestId,
      });
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  sendPasswordReset: async function (email: string, browserInfo: any) {
    try {
      return ApiService.postJson<any>(UserEndpoint.sendPasswordReset, {
        email,
        browserInfo: JSON.stringify(browserInfo),
      });
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  resetPassword: async function (
    token: any,
    email: string,
    newPassword: string
  ) {
    try {
      return postFormPromise("/User/ResetPassword/", {
        token,
        email,
        newPassword,
      });
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  verifyResetToken: async function (token: any, email: string) {
    try {
      return postFormPromise("/User/VerifyResetToken/", { token, email });
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  upcomingSessions: async function (take: number) {
    try {
      return ApiService.get<UpcomingSession[]>(
        `/User/UpcomingSessions/?take=${take}`
      );
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  allGenres: async function () {
    try {
      return ApiService.get<any>(`/public/AllGenres/`);
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  canRegister: async function (email: string) {
    try {
      return ApiService.get<any>("/User/CanRegister?email=" + email);
    } catch (error: any) {
      throw new ResponseError(error.status, error.error.message);
    }
  },
  updateProfile: async function (profile: any) {
    return new Promise((resolve, reject) => {
      return ApiService.postJson<any>(UserEndpoint.updateProfile, {
        FirstName: profile.firstName,
        LastName: profile.lastName,
        Bio: profile.Bio ?? "",
        Gender: profile.gender,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  updateProfilePicture: async function (pictureData: any) {
    return new Promise((resolve, reject) => {
      return ApiService.postForm<any>(
        UserEndpoint.updateProfilePicture,
        pictureData
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.message || err);
        });
    });
  },
  uploadUserImage: async function (
    pictureData: any
  ): Promise<RequestResult<string>> {
    return new Promise((resolve, reject) => {
      return ApiService.postForm<any>("/User/UploadUserImage/", pictureData)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.message || err);
        });
    });
  },
};

export { UserService };

const defaultProfileUrl = `local/img/avatar.png`;

const defaultGroupPhotoSize = {
  width: 1200,
  height: 515,
};
const defaultSessionPhotoSize = {
  width: 1200,
  height: 515,
};

const ratingLabelMap = new Map();
ratingLabelMap.set(10, "Beginner");
ratingLabelMap.set(20, "Competent");
ratingLabelMap.set(40, "Intermediate");
ratingLabelMap.set(60, "Advanced");
ratingLabelMap.set(80, "Expert");
const ratingLabel = (rating: number) => {
  if (!rating) return "Not Set";
  if (ratingLabelMap.has(rating)) return ratingLabelMap.get(rating);
  if (rating < 10 && rating > 0) {
    return "Beginner: " + rating;
  } else if (rating > 20 && rating < 40) {
    return "Competent: " + rating;
  } else if (rating > 40 && rating < 60) {
    return "Intermediate: " + rating;
  } else if (rating > 60 && rating < 80) {
    return "Advanced: " + rating;
  }
  return "Expert: " + rating;
};

const keysValueToArrayTyped = <ReturnType>(obj: any): Array<ReturnType> => {
  const keysToArray = Object.keys(obj); // Get the keys as an array
  return keysToArray.map((key) => obj[key]);
};

/**
 * Return the unique items in an array
 * @param array
 * @param keyValueFn - a function to return the unique key value for the item
 */
const unique = <T, TKey>(
  array: Array<T>,
  keyValueFn: { (argument: T): TKey }
): Array<T> => {
  const keys = new Map<TKey, boolean>();
  const ret = [] as T[];
  const arrayTarget = [...array];
  for (let i = 0; i < arrayTarget.length; i++) {
    const row = arrayTarget[i];
    const key = keyValueFn(row);
    if (!keys.has(key)) {
      keys.set(key, true);
      ret.push(row);
    }
  }
  return ret;
};

export {
  defaultProfileUrl,
  defaultGroupPhotoSize,
  defaultSessionPhotoSize,
  ratingLabel,
  keysValueToArrayTyped,
  unique,
};


import {
    IonAvatar,

} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
        IonAvatar,
    },
    props: ["menu"]

});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_icon = _resolveComponent("menu-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list_group = _resolveComponent("v-list-group")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.menu.submenu && !_ctx.menu.popup)
      ? (_openBlock(), _createBlock(_component_v_list_item, {
          key: 0,
          class: _normalizeClass(_ctx.className),
          value: _ctx.menu,
          color: _ctx.colorForMode,
          active: _ctx.selected(_ctx.menu),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.menuClick(_ctx.menu)))
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_menu_icon, { menu: _ctx.menu }, null, 8, ["menu"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_list_item_title, {
              textContent: _toDisplayString(_ctx.menu.label)
            }, null, 8, ["textContent"]),
            (_ctx.menu.subtitle)
              ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                  key: 0,
                  textContent: _toDisplayString(_ctx.menu.subtitle)
                }, null, 8, ["textContent"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["class", "value", "color", "active"]))
      : _createCommentVNode("", true),
    (_ctx.menu.submenu)
      ? (_openBlock(), _createBlock(_component_v_list_group, {
          key: 1,
          class: _normalizeClass(_ctx.className),
          value: _ctx.menu.label
        }, {
          activator: _withCtx(({ props }) => [
            _createVNode(_component_v_list_item, _mergeProps(props, {
              title: _ctx.menu.label
            }), {
              prepend: _withCtx(() => [
                _createVNode(_component_menu_icon, { menu: _ctx.menu }, null, 8, ["menu"])
              ]),
              _: 2
            }, 1040, ["title"])
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.submenu, (submenu) => {
              return (_openBlock(), _createBlock(_component_v_list_item, {
                onClick: ($event: any) => (_ctx.menuClick(submenu)),
                key: submenu.section,
                title: submenu.label,
                value: submenu.label
              }, {
                prepend: _withCtx(() => [
                  _createVNode(_component_menu_icon, { menu: submenu }, null, 8, ["menu"])
                ]),
                _: 2
              }, 1032, ["onClick", "title", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["class", "value"]))
      : _createCommentVNode("", true),
    (_ctx.menu.popup)
      ? (_openBlock(), _createBlock(_component_v_menu, { key: 2 }, {
          activator: _withCtx(({ props }) => [
            _createVNode(_component_v_list_item, _mergeProps(props, {
              class: _ctx.className,
              value: _ctx.menu,
              color: "primary",
              active: _ctx.selected(_ctx.menu)
            }), {
              prepend: _withCtx(() => [
                _createVNode(_component_menu_icon, { menu: _ctx.menu }, null, 8, ["menu"])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_list_item_title, {
                  textContent: _toDisplayString(_ctx.menu.label)
                }, null, 8, ["textContent"]),
                (_ctx.menu.subtitle)
                  ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                      key: 0,
                      textContent: _toDisplayString(_ctx.menu.subtitle)
                    }, null, 8, ["textContent"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1040, ["class", "value", "active"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.popup.items, (item) => {
                  return (_openBlock(), _createBlock(_component_v_list_item, {
                    key: item.label,
                    value: item.label,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.menuClick(_ctx.menu)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.label), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
import { getRequestResult, postJsonPromise } from "../services/base.service";
import localForage from "localforage";

export interface CacheSettings {
  localStoreName?: string;
  diskCacheExpireMinutes?: number;
  memCacheExpiresMs?: number;
  debug?: boolean;
  fetchSingleUrl?: string | ((key: string) => string);
  fetchSingleMethod?: "get" | "post";
}
const defaultCacheSettings = {
  localStoreName: null,
  diskCacheExpireMinutes: 0,
  memCacheExpiresMs: 0,
  debug: false,
  fetchSingleUrl: null,
} as unknown as CacheSettings;
interface CacheRecord {
  payload: any;
  expires: number;
}
const NetworkBackedCache = <ValueType>(
  fetchUrl: string,
  cacheSettings?: CacheSettings
) => {
  let _cache = {} as {
    [key: string]: {
      payload: ValueType;
      expires?: number;
    };
  };
  let _hitCount = 0;
  let _missCount = 0;
  let _size = 0;

  const settings = cacheSettings ?? defaultCacheSettings;
  settings.diskCacheExpireMinutes =
    settings.diskCacheExpireMinutes ??
    defaultCacheSettings.diskCacheExpireMinutes;
  settings.memCacheExpiresMs =
    settings.memCacheExpiresMs ?? defaultCacheSettings.memCacheExpiresMs;

  const _localStoreName = settings.localStoreName;

  let store = {} as LocalForage;
  if (_localStoreName) {
    store = localForage.createInstance({
      driver: [
        localForage.INDEXEDDB,
        localForage.LOCALSTORAGE,
        localForage.WEBSQL,
      ],
      name: _localStoreName, // These fields
      version: 1.0, // are totally optional
    });
  }
  const _fetchUrl = fetchUrl;
  const put = function (key: string, value: ValueType) {
    _put(key, value);
    cacheToDisk(key, value);
    return value;
  };
  const _put = (key: string, value: ValueType) => {
    const oldRecord = _cache[key];
    if (!oldRecord) {
      _size++;
    }
    let expires;

    if (settings.memCacheExpiresMs) {
      expires = settings.memCacheExpiresMs + Date.now();
    }

    const record = {
      payload: value,
      expires: expires,
    };
    log(`Memcache Add: ${key}`, record);
    _cache[key] = record;
  };
  const log = (msg: string, payload?: any) => {
    if (!settings.debug) return;
    let data = msg;
    if (payload) {
      data += JSON.stringify(payload);
    }
    console.log(`NBC [${settings.localStoreName}]: ${data}`);
  };

  const cacheToDisk = async function (key: string, value: ValueType) {
    if (!_localStoreName) return;
    let expires;
    if (settings.diskCacheExpireMinutes) {
      expires = settings.diskCacheExpireMinutes * 60000 + Date.now();
    } else {
      expires = 24 * 60 * 60000 + Date.now(); // 24 hours, 60 minutes in an hour, 60000 ms in a minute
    }
    const record = {
      payload: value,
      expires: expires,
    } as CacheRecord;
    log(`Diskcache Add: ${key}`, record);
    await store.setItem(key, record);
  };

  const del = async function (key: string) {
    const oldRecord = _cache[key];
    const missing = !oldRecord;
    if (!missing) {
      await _del(key);
    }
    if (!_localStoreName) return;
    await store?.removeItem(key);
  };

  const _del = async function (key: string) {
    _size--;
    delete _cache[key];
  };

  const clear = function () {
    _size = 0;
    _cache = {};
    store?.clear();
    if (settings.debug) {
      _hitCount = 0;
      _missCount = 0;
    }
  };

  const cacheRemaining = (dictionary: any) => {
    for (const key in dictionary) {
      put(key, dictionary[key]);
    }
  };
  const fetchMissing = async (keys: Array<string>): Promise<any> => {
    return postJsonPromise(
      _fetchUrl,
      keys.map((x) => parseInt(x))
    );
  };
  const fetchMissingSingle = async (key: string): Promise<any> => {
    const url =
      typeof settings.fetchSingleUrl === "function"
        ? settings.fetchSingleUrl(key)
        : settings.fetchSingleUrl + key;

    if (settings.fetchSingleMethod === "get") {
      return getRequestResult(url);
    } else {
      return postJsonPromise(url, {});
    }
  };
  const getFromDisk = async (key: string): Promise<ValueType | undefined> => {
    if (!_localStoreName) return;
    const keyStr = key.toString();
    const value = await store.getItem<CacheRecord>(keyStr);
    log(`GetFromDisk ${key} returned`, value);

    if (value) {
      if (!value.expires) {
        return value.payload;
      }
      if (value.expires > Date.now()) {
        return value.payload; // payload hasn't expired yet
      } else {
        log(`GetFromDisk ${key} payload expired`);
        store.removeItem(keyStr); // payload has expired, remove from store;
      }
    }
    return undefined;
  };
  const getAll = async (
    keys: string[]
  ): Promise<{ [key: string]: ValueType }> => {
    let ret = {} as { [key: string]: ValueType };
    const missing = [] as string[];

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      let value = getFromMemory(key);
      if (!value) {
        value = await getFromDisk(key);
        if (value) _put(key, value); // got it from our disk cache, let's cache it here.
      }
      if (value) {
        ret[key] = value;
      } else {
        missing.push(key);
      }
    }

    if (missing.length > 0) {
      const remaining = await fetchMissing(missing);
      cacheRemaining(remaining);
      ret = { ...ret, ...remaining };
    }
    return ret;
  };

  const get = async function (key: string): Promise<ValueType | undefined> {
    return new Promise((resolve, reject) => {
      const data = getFromMemory(key);
      if (data) {
        resolve(data);
        return;
      }
      getFromDisk(key)
        .then((data) => {
          if (data) {
            _put(key, data);
            resolve(data);
            return;
          } else {
            if (settings.fetchSingleUrl) {
              fetchMissingSingle(key)
                .then((result) => {
                  if (result) {
                    put(key, result);
                    resolve(result);
                  } else {
                    resolve(undefined);
                  }
                })
                .catch(reject);
            }
          }
        })
        .catch(reject);
    });
  };
  const getFromMemory = function (key: string): ValueType | undefined {
    const data = _cache[key];
    log(`Get ${key} returned ${data}`);
    if (typeof data != "undefined") {
      if (!data.expires) {
        return data.payload;
      }
      if (data.expires > Date.now()) {
        return data.payload;
      } else {
        log(`Get ${key} expired`);
        _size--;
        delete _cache[key];
      }
    } else if (settings.debug) {
      _missCount++;
    }
    return;
  };

  const size = function () {
    return _size;
  };

  const debug = function (bool: boolean) {
    settings.debug = bool;
  };

  const hits = function () {
    return _hitCount;
  };

  const misses = function () {
    return _missCount;
  };

  const keys = function () {
    return Object.keys(_cache);
  };

  return {
    get,
    del,
    getAll,
    keys,
    misses,
    hits,
    debug,
    size,
    clear,
  };
};
export default NetworkBackedCache;

import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d283a72"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_UserAvatarVuetify = _resolveComponent("UserAvatarVuetify")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "notification-popup pa-4",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNotification())),
    transition: "slide-y-reverse-transition",
    elevation: "16"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_subtitle, null, {
        default: _withCtx(() => [
          _createTextVNode(" New Notification ")
        ]),
        _: 1
      }),
      _createVNode(_component_v_list_item, null, {
        prepend: _withCtx(() => [
          _createVNode(_component_UserAvatarVuetify, {
            size: "48",
            user: _ctx.user
          }, null, 8, ["user"])
        ]),
        subtitle: _withCtx(() => [
          _createElementVNode("div", {
            innerHTML: _ctx.item.feedItem.notificationTypeName
          }, null, 8, _hoisted_1)
        ]),
        default: _withCtx(() => [
          _createTextVNode(" " + _toDisplayString(_ctx.item.feedItem.fromUser), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_font_awesome_icon, {
        onClick: _withModifiers(_ctx.close, ["stop"]),
        icon: "fa-solid fa-circle-xmark",
        class: "close-icon"
      }, null, 8, ["onClick"])
    ]),
    _: 1
  }))
}
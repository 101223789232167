import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-674f85f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "space-x-5 flex flex-row align-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_TwicImg = _resolveComponent("TwicImg")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      false
        ? (_openBlock(), _createBlock(_component_ion_header, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, { color: "primary" }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "toolbar", {}, undefined, true),
                  _renderSlot(_ctx.$slots, "buttons", {}, () => [
                    _createVNode(_component_ion_buttons, { slot: "start" }, {
                      default: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "actions-start", {}, undefined, true),
                        _createVNode(_component_ion_menu_button),
                        _createVNode(_component_ion_back_button)
                      ]),
                      _: 3
                    })
                  ], true),
                  _renderSlot(_ctx.$slots, "title", {}, () => [
                    _createVNode(_component_ion_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.pageTitle), 1)
                      ]),
                      _: 1
                    })
                  ], true),
                  _createVNode(_component_ion_buttons, { slot: "primary" }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "primary-buttons", {}, () => [
                        _createVNode(_component_ion_avatar, {
                          onClick: _ctx.showNotifications,
                          slot: "icon-only",
                          class: "toolbar-avatar"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, {
                              icon: "fa-regular fa-bell",
                              class: _normalizeClass(`notification-icon ${_ctx.notificationCount > 0 ? 'active' : 'none'}`)
                            }, null, 8, ["class"]),
                            (_ctx.notificationCount > 0)
                              ? (_openBlock(), _createBlock(_component_ion_badge, {
                                  key: 0,
                                  class: "notification-count",
                                  color: "danger"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.notificationCount), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_ion_avatar, {
                          onClick: _ctx.showAvatarMenu,
                          slot: "icon-only",
                          class: "toolbar-avatar clickable"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_TwicImg, {
                              class: "avatar-image",
                              alt: _ctx.profile?.first,
                              src: _ctx.profile?.imageUrl || _ctx.defaultProfileUrl,
                              ratio: "1/1"
                            }, null, 8, ["alt", "src"])
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ], true)
                    ]),
                    _: 3
                  })
                ]),
                _: 3
              })
            ]),
            _: 3
          }))
        : _createCommentVNode("", true),
      (_ctx.showAppNavigation)
        ? (_openBlock(), _createBlock(_component_v_toolbar, {
            key: 1,
            class: "top-toolbar",
            elevation: 2,
            color: "primary",
            density: "compact"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_app_bar_nav_icon, {
                onClick: _withModifiers(_ctx.toggleNavDrawer, ["stop"])
              }, null, 8, ["onClick"]),
              _createVNode(_component_app_icon, { class: "toolbar-app-icon" }),
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.pageTitle), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                icon: "",
                onClick: _ctx.showNotifications
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_avatar, { class: "toolbar-avatar notification" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: "fa-solid fa-bell",
                        class: _normalizeClass(`notification-icon ${_ctx.notificationCount > 0 ? 'active' : 'none'}`)
                      }, null, 8, ["class"]),
                      (_ctx.notificationCount > 0)
                        ? (_openBlock(), _createBlock(_component_v_chip, {
                            key: 0,
                            variant: "elevated",
                            class: "notification-count notification-chip",
                            size: "x-small"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.notificationCount), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                icon: "",
                onClick: _ctx.showAvatarMenu
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_avatar, {
                    slot: "icon-only",
                    class: "toolbar-avatar profile"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TwicImg, {
                        class: "avatar-image",
                        alt: _ctx.profile?.first,
                        src: _ctx.profile?.imageUrl || _ctx.defaultProfileUrl,
                        ratio: "1/1"
                      }, null, 8, ["alt", "src"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.showGuestNavigation)
        ? (_openBlock(), _createBlock(_component_v_toolbar, {
            key: 2,
            class: "top-toolbar px-4 py-2",
            elevation: 2,
            color: "primary",
            density: "compact"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_app_icon, { class: "toolbar-app-icon" }),
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.pageTitle), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_spacer),
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Welcome Guest")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_button, {
                  color: "success",
                  onClick: _ctx.signUp
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Sign-Up ")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_ion_button, {
                  fill: "outline",
                  color: "secondary",
                  onClick: _ctx.signIn
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Sign-In ")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "content", {}, () => [
        _createVNode(_component_ion_content, {
          "scroll-y": _ctx.scrollPage,
          id: "ion-page-content",
          class: _normalizeClass(["main-page-content", _ctx.contentClass]),
          onIonScrollEnd: _ctx.onScrollEnd,
          scrollEvents: true,
          ref: "ionContent"
        }, {
          default: _withCtx(() => [
            (_ctx.onPullRefresh)
              ? (_openBlock(), _createBlock(_component_ion_refresher, {
                  key: 0,
                  slot: "fixed",
                  onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleRefresh($event)))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_refresher_content)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["scroll-y", "onIonScrollEnd", "class"])
      ], true)
    ]),
    _: 3
  }))
}
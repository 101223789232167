import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { UserService } from "@/services/user.service";
import { UpcomingSession } from "@/types/upcoming-session";
export const useUserStore = defineStore("UserStore", () => {
  const sessionsLoaded = ref(false);
  const upcomingSessionList = ref<UpcomingSession[]>([]);
  const is_loading = ref(false);
  const upcomingSessions = computed(() => upcomingSessionList.value);
  const loading = computed(() => is_loading.value);
  const upcomingLoaded = computed(() => sessionsLoaded.value);

  const loadUpcomingSessions = async (take: number) => {
    is_loading.value = true;
    return new Promise((resolve, reject) => {
      UserService.upcomingSessions(take)
        .then((res) => {
          upcomingSessionList.value = res;
          is_loading.value = false;
          sessionsLoaded.value = true;
          resolve(res);
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  };

  return {
    upcomingSessions,
    loading,
    loadUpcomingSessions,
    upcomingLoaded,
  };
});

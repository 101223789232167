import { ref } from "vue";

export const useTabActive = () => {
  const visible = ref(!document.hidden);
  document.addEventListener("visibilitychange", () => {
    visible.value = !document.hidden;
  });

  return {
    visible,
  };
};

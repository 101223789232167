import { RequestResult } from "@/types/request-result";
import ApiService from "./api.service";

function isRequestResult<T>(obj: any): obj is RequestResult<T> {
  return obj.success !== undefined && obj.payload !== undefined;
}
const postJsonRequestResult = <T>(url: string, data: any): Promise<T> => {
  return new Promise((resolve, reject) => {
    return ApiService.postJson<T>(url, data)
      .then((res: any) => {
        const success =
          res === true ? true : "success" in res ? res.success : true;
        if (success) {
          if (isRequestResult<T>(res)) {
            resolve(res.payload);
          } else {
            resolve(res);
          }
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};
const postJsonPromise = <T>(url: string, data: any): Promise<T> => {
  return new Promise((resolve, reject) => {
    return ApiService.postJson<T>(url, data)
      .then((res: any) => {
        const success =
          res === true ? true : "success" in res ? res.success : true;
        if (success) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};
const postFormPromise = <T>(url: string, data: any): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    return ApiService.postForm<T>(url, data)
      .then((res: any) => {
        const success =
          res === true ? true : "success" in res ? res.success : true;
        if (success) {
          resolve(res as T);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

const getRequestResult = (url: string) => {
  return new Promise((resolve, reject) => {
    return ApiService.get<any>(url)
      .then((data: any) => {
        const success =
          data === true ? true : "success" in data ? data.success : true;

        if (success) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export {
  postFormPromise,
  getRequestResult,
  postJsonPromise,
  postJsonRequestResult,
};

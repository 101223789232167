import { defineStore } from "pinia";
import { ref, computed, watch } from "vue";
//import { defaultMenu } from '@/models/default-menu';
import { useIonRouter } from "@ionic/vue";
import { Guid } from "@/utils";
import { useGroupStore } from "@/store/group.store";
import { useUserStore } from "@/store/user.store";
import { DateFormatter } from "@/utils";
import { useAuthStore } from "@/store/auth.store";
import { defaultProfileUrl } from "@/utils/defaults";
import { useDisplay } from "vuetify";
import { useAuthNav } from "@/composables/useAuthNav";
export const usePageStore = defineStore("PageStore", () => {
  const activeMenuState = ref(null as any);
  const groupStore = useGroupStore();
  const showMenu = ref(true);
  const groups = computed(() => groupStore.userGroups);
  const showSideMenu = computed(() => showMenu.value);
  const menuDetailState = ref({} as any);
  const activeMenu = computed(() => activeMenuState.value);
  const menuDetails = computed(() => menuDetailState.value);
  const activeMenuPath = ref(null as any);
  const userStore = useUserStore();
  const authStore = useAuthStore();
  const display = useDisplay();
  const leftDrawer = ref(display.lgAndUp);
  const upcomingSessions = computed(() => userStore.upcomingSessions);
  const notificationCount = computed(() => authStore.unreadNotifications);
  const dmCount = computed(() => authStore.unreadDm);
  const authNav = useAuthNav();
  const hideMenu = (hide: boolean) => {
    showMenu.value = !hide;
  };
  const toggleLeftDrawer = () => {
    leftDrawer.value = !leftDrawer.value;
  };
  const setActiveMenu = (value: any) => {
    activeMenuState.value = value;
  };
  const setMenuItems = (value: any) => {
    topMenu.value = value;
  };
  const formatDateTime = (d: any) => {
    return DateFormatter.parseShortDateStringTimeToday(d);
  };
  const logout = () => {
    authNav.navToSignIn("/");

    //ionRouter.replace("/sign-in");
  };
  const defaultBottomMenu = computed(() => {
    return {
      label: "More",
      items: [
        {
          label: authStore.profile?.first,
          image: authStore.profile?.imageUrl || defaultProfileUrl,
          link: "/profile",
        },
        {
          label: "Sign-Out",
          icon: "fa-solid fa-right-from-bracket",
          click: logout,
        },
      ],
    };
  });
  const defaultMenu = computed(() => {
    return [
      {
        id: Guid.newGuid(),
        label: "",
        items: [
          {
            label: "Home",
            link: `/`,
            icon: "fa-solid fa-home",
            nav: "replace",
          },
          {
            label: "Notifications",
            link: `/notifications`,
            icon: "fa-solid fa-bell",
            notificationCount: notificationCount,
          },

          {
            label: "Direct Messages",
            link: `/dm`,
            icon: "fa-solid fa-messages",
            notificationCount: dmCount,
          },
        ],
      },
      {
        id: Guid.newGuid(),
        label: "My Groups",
        items: [
          ...groupStore.userGroups.map((x: any) => ({
            id: Guid.newGuid(),
            label: x.sessionGroup.name,
            link: `/group/${x.sessionGroup.id}`,
            nav: "replace",
            icon: "fa-solid fa-people-group",
            image: x.sessionGroup.imageAssetData,
          })),
          {
            id: Guid.newGuid(),
            label: "More Groups",
            link: `/groups`,
            nav: "replace",
            icon: "fa-solid fa-people-group",
          },
        ],
      },
      {
        id: Guid.newGuid(),
        label: "Upcoming Sessions",
        items: upcomingSessions.value.map((x: any) => ({
          id: Guid.newGuid(),
          label: x.title,
          subtitle: `${formatDateTime(x.startDate)} @ ${x.location}`,
          link: `/session/${x.id}`,
          nav: "replace",
          image: x.imageAssetData || "https://placehold.co/48x48/000000/FFF",
        })),
      },
    ];
  });

  const topMenu = ref(defaultMenu.value as any);
  const bottomMenu = ref(defaultBottomMenu.value as any);
  const isDefaultMenu = ref(true);
  const refreshDefaultMenu = () => {
    if (!isDefaultMenu.value) return;
    setDefaultMenu();
  };
  const setDefaultMenu = () => {
    setDefaultMenuForPath();
  };
  const setDefaultMenuForPath = () => {
    isDefaultMenu.value = true;
    activeMenuPath.value = null;
    topMenu.value = defaultMenu.value;
    bottomMenu.value = defaultBottomMenu.value;
  };
  const setTopAndBottom = (top: any, bottom: any, path: any) => {
    isDefaultMenu.value = false;
    topMenu.value = top;
    bottomMenu.value = bottom;
    activeMenuPath.value = path;
  };

  const setActiveMenuWithDetails = (value: any, details: any) => {
    activeMenuState.value = value;
    menuDetailState.value = details;
  };
  watch(
    groups,
    async () => {
      console.log("Groups changed In PageStore");
      refreshDefaultMenu();
    },
    { deep: true }
  );
  watch(
    upcomingSessions,
    async () => {
      console.log("Sessions changed In PageStore");
      refreshDefaultMenu();
    },
    { deep: true }
  );
  watch(
    activeMenu,
    async () => {
      console.log("Active Menu Changed in PageStore");
      console.log(activeMenu);
    },
    { deep: true }
  );

  return {
    activeMenu,
    setActiveMenu,
    menuDetails,
    setActiveMenuWithDetails,
    setMenuItems,
    setDefaultMenu,
    topMenu,
    bottomMenu,
    setTopAndBottom,
    leftDrawer,
    toggleLeftDrawer,
    hideMenu,
    showSideMenu,
  };
});

import { defineStore } from "pinia";
import { AuthenticationError, AuthService } from "@/services/auth.service";
import { TokenService } from "@/services/token.service";
import { MessageService } from "@/services/message.service";
import { ref, computed } from "vue";
import { UserNotificationDetailed } from "@/types/user-notification-detailed";
import { MessageChannelType } from "@/types/message-channel-type";
import { useClientVersionStore } from "./client-version.store";
export const useAuthStore = defineStore("AuthStore", () => {
  const checkTime = ref(new Date(0));
  const clientVersionStore = useClientVersionStore();
  const state = ref({
    is_authenticating: false,
    access_token: TokenService.getToken(),
    auth_err_code: 0,
    auth_error: "",
    refresh_token_promise: null as any,
    user_profile: TokenService.getProfile(),
    refresh_token: TokenService.getRefreshToken(),
    refresh_at: TokenService.getRefreshAt(),
    user_notifications: [] as UserNotificationDetailed[] | undefined,
    last_notification: 0,
    auth_checked: false,
    refreshing_token: false,
    popup_notification: null as any,
  });

  const secondsSinceLastCheck = () => {
    const seconds =
      Math.abs(checkTime.value.getTime() - new Date().getTime()) / 1000;
    return seconds;
  };
  const authenticationErrorCode = computed(() => {
    return state.value.auth_err_code;
  });
  const popupNotification = computed(() => {
    return state.value.popup_notification;
  });
  const authChecked = computed(() => {
    return state.value.auth_checked;
  });
  const refreshingToken = computed(() => {
    return state.value.refreshing_token;
  });
  const authenticationError = computed(() => {
    return state.value.auth_error;
  });
  const notifications = computed(() => {
    return state.value.user_notifications;
  });
  const unreadNotifications = computed(() => {
    return (
      state.value.user_notifications?.reduce(
        (v: number, cv: UserNotificationDetailed) => {
          if (!cv.notification.isRead) {
            return v + cv.notification.unreadCount;
          }
          return v;
        },
        0
      ) || 0
    );
  });
  const unreadDm = computed(() => {
    return (
      state.value.user_notifications?.reduce(
        (v: number, cv: UserNotificationDetailed) => {
          if (
            cv.notification.channelType == MessageChannelType.Private &&
            !cv.notification.isRead
          ) {
            return v + cv.notification.unreadCount;
          }
          return v;
        },
        0
      ) || 0
    );
  });
  const authenticating = computed(() => {
    return state.value.is_authenticating;
  });
  const authenticated = computed(() => {
    return !state.value.access_token == false;
  });
  const profile = computed(() => {
    return state.value.user_profile ? state.value.user_profile : null;
  });

  const setRefreshingToken = (value: boolean) => {
    state.value.refreshing_token = value;
  };
  const setPopupNotification = (value: any) => {
    state.value.popup_notification = value;
  };
  const signIn = async (signInData: any) => {
    state.value.is_authenticating = true;
    state.value.auth_error = "";
    state.value.auth_err_code = 0;

    return new Promise((resolve, reject) => {
      AuthService.signIn(signInData)
        .then((data: any) => {
          if (data.success) {
            state.value.access_token = data.token;
            state.value.user_profile = data.profile;
            state.value.is_authenticating = false;
            setAuthChecked();
          } else {
            state.value.is_authenticating = false;
            state.value.auth_err_code = -1;
            state.value.auth_error = signInData.message;
          }
          resolve(data);
        })
        .catch((err) => {
          if (err instanceof AuthenticationError) {
            state.value.is_authenticating = false;
            state.value.auth_err_code = err.errorCode;
            state.value.auth_error = err.message;
            reject(err.message);
          }
        });
    });
  };
  const setAuthChecked = () => {
    state.value.auth_checked = true;
  };

  const signOut = () => {
    state.value.is_authenticating = false;
    state.value.access_token = null;
    state.value.user_profile = null;
    AuthService.signOut();
  };
  const refreshNotifications = async () => {
    if (secondsSinceLastCheck() < 15) {
      console.log("Skipping notification check:" + secondsSinceLastCheck());
      return new Promise((resolve) => {
        resolve(null);
      });
    }
    checkTime.value = new Date();
    console.log("Checking for new notifications");
    return new Promise((resolve, reject) => {
      MessageService.userFeed()
        .then((result) => {
          if (result.success) {
            state.value.user_notifications = result.payload;
            if (result.payload.length > 0) {
              const last = result.payload[result.payload.length - 1];
              state.value.last_notification = last.notification.id;
            }
            if (result.latestClientVersion) {
              clientVersionStore.setLatestClientVersion(
                result.latestClientVersion
              );
            }
          }
          resolve(result);
        })
        .catch((err: any) => {
          reject(err.message);
        });
    });
  };
  const refreshToken = async () => {
    if (!state.value.refresh_token_promise) {
      const p = AuthService.refreshToken();
      state.value.refresh_token_promise = p;
      p.then(
        () => {
          state.value.refresh_token_promise = null;
          //state.value.access_token = data.token;
          //what to do here?
          // context.commit("loginSuccess", response);
        },
        (error) => {
          state.value.refresh_token_promise = error;
        }
      );
    }

    return state.value.refresh_token_promise;
  };
  // const signup = async ({ email, password, name }: any) => {
  //   try {
  //     await AuthService.signup(email, password, name);
  //     state.value.is_authenticating = false;
  //     return true;
  //   } catch (e) {
  //     if (e instanceof AuthenticationError) {
  //       state.value.is_authenticating = false;
  //       state.value.auth_err_code = e.errorCode;
  //       state.value.auth_error = e.message;
  //     }
  //     return false;
  //   }
  // };

  return {
    authenticationErrorCode,
    authChecked,
    authenticationError,
    notifications,
    unreadNotifications,
    unreadDm,
    authenticating,
    authenticated,
    profile,
    refreshingToken,
    popupNotification,
    signIn,
    setRefreshingToken,
    setAuthChecked,
    signOut,
    refreshNotifications,
    refreshToken,
    // signup,
    setPopupNotification,
  };
});

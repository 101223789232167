import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_menu_item = _resolveComponent("menu-item")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createBlock(_component_v_list, {
    class: "left-menu ion-no-padding",
    opened: _ctx.opened,
    "onUpdate:opened": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.opened) = $event))
  }, {
    default: _withCtx(() => [
      (_ctx.menuItems.label)
        ? (_openBlock(), _createBlock(_component_v_list_subheader, {
            key: 0,
            class: "app-menu-subhead"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.menuItems.label), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems.items, (menu) => {
        return (_openBlock(), _createBlock(_component_menu_item, {
          className: "menu-item-indent",
          menu: menu,
          key: menu.section,
          onItemClick: _ctx.menuClick
        }, null, 8, ["menu", "onItemClick"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["opened"]))
}

import { Side } from "@ionic/core";
import {
    useIonRouter,

} from "@ionic/vue";
import AppIcon from "../../icon/AppIcon.vue";
import { defineComponent, ref, computed } from "vue";
import { usePageStore } from "@/store/page.store";
import MenuList from "./MenuList.vue";
export default defineComponent({
    components: {
        MenuList,
        AppIcon,
    },
    props: {
        title: { type: String, default: 'Soundhaus' },
        side: { type: String, default: 'start' },
        menuId: { type: String, default: undefined, require: true },
        contentId: { type: String, default: 'app-router-content', require: true },
        type: { type: String, default: 'overlay' },
        color: { type: String, default: 'primary' },
    },
    emits: ["close"],
    setup(props, context) {
        const pageStore = usePageStore();
        const menuSide = computed(() => props.side ? props.side as Side : 'start' as Side);
        const ionRoute = useIonRouter();
        const menuItems = computed(() => pageStore.topMenu);
        const bottomMenu = computed(() => pageStore.bottomMenu);
        const closeModal = () => {
            context.emit('close', {
                menuId: props.menuId,
                side: props.side,
            })
        };





        const selectedSubMenu = ref(null as any);

        const menuClick = async (item: any) => {
            const currentSubMenu = selectedSubMenu.value?.label;
            selectedSubMenu.value = null;

            if (item.link) {
                if (item.nav == 'replace') {
                    ionRoute.replace(item.link);
                } else {
                    ionRoute.push(item.link);
                }
            } else if (item.submenu) {
                if (currentSubMenu != item.label) {
                    selectedSubMenu.value = item;
                }
            }
        }


        return {
            selectedSubMenu,
            menuClick,
            menuItems,
            closeModal,
            menuSide,
            bottomMenu
        };
    },
});

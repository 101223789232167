import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import vuetify from "@/plugins/vuetify";
import IonicVue from "@/plugins/ionic";
import router from "./router";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonIcon,
  IonItem,
  IonLabel,
  IonButton,
  IonContent,
  IonCard,
  IonInput,
  IonCardTitle,
  IonCardSubtitle,
  IonCardHeader,
  IonCardContent,
  IonTitle,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonText,
} from "@ionic/vue";
import ApiService from "./services/api.service";
import DesktopMobileLayout from "./components/layouts/DesktopMobileLayout.vue";
import DefaultLayout from "./components/layouts/DefaultLayout.vue";
import { defineCustomElements } from "@ionic/pwa-elements/loader";

import FontAwesomeIcon from "@/plugins/font-awesome";
import TwicPics from "@/plugins/twic-pics";
import useInterceptors from "./services/interceptors";
import LogRocket from "logrocket";

const pinia = createPinia();

defineCustomElements(window);
if (process.env.NODE_ENV === "production") {
  const useLogRocket = new Date().getMilliseconds() % 2 === 0;
  // random 50% of users to use logrocket
  localStorage.setItem("logrocket:enabled", useLogRocket.toString());
  if (useLogRocket) {
    LogRocket.init("splbec/tripoli", {
      shouldDebugLog: false,
    });
  }
}

const app = createApp(App)
  .use(vuetify)
  .use(IonicVue)
  .use(router)
  .use(TwicPics, {
    domain: "https://soundhaus.twic.pics",
  })
  .use(pinia)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("ion-grid", IonGrid)
  .component("ion-row", IonRow)
  .component("ion-col", IonCol)
  .component("ion-list", IonList)
  .component("ion-icon", IonIcon)
  .component("ion-label", IonLabel)
  .component("ion-item", IonItem)
  .component("ion-button", IonButton)
  .component("ion-content", IonContent)
  .component("ion-card", IonCard)
  .component("ion-input", IonInput)
  .component("ion-card-title", IonCardTitle)
  .component("ion-card-subtitle", IonCardSubtitle)
  .component("ion-card-header", IonCardHeader)
  .component("ion-card-content", IonCardContent)
  .component("ion-title", IonTitle)
  .component("ion-header", IonHeader)
  .component("ion-toolbar", IonToolbar)
  .component("ion-buttons", IonButtons)
  .component("ion-text", IonText)
  .component("desktop-mobile-layout", DesktopMobileLayout)
  .component("default-layout", DefaultLayout);

ApiService.init(process.env.VUE_APP_ROOT_API, process.env.VUE_APP_VERSION);
console.log("Tripoli Client Version: ", process.env.VUE_APP_CLIENT_VERSION);
router.isReady().then(async () => {
  const promise = useInterceptors(router);
  promise.finally(() => {
    app.mount("#app");
  });
});

import { format as formatFns, parseISO } from "date-fns";
const TrackFormatter = {
  formatTrackDuration: (durationSeconds) => {
    var mins = Math.round(durationSeconds / 60 - 0.5);
    var seconds = Math.round((durationSeconds % 60) - 0.05);
    return `${mins}:${seconds < 10 ? "0" + seconds : seconds}`;
  },
};

const UriParser = {
  pathValueNext: function (url, key) {
    // Split the URL string by '/'
    const urlParts = url.split("/");
    // Find the index of the key  in the array
    const keyIndex = urlParts.findIndex((part) => part === key);
    return urlParts[keyIndex + 1];
  },
};

const DateFormatter = {
  /*const dtFormatter = new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    hour: "numeric",
  });*/
  toShortTimeString: function (d) {
    var h = d.getHours();
    var m = d.getMinutes();
    var s = d.getSeconds();
    return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${
      s < 10 ? "0" + s : s
    }`;
  },
  toShortTimeString12Hour: function (d) {
    var h = d.getHours();
    var m = d.getMinutes();
    //var s = d.getSeconds();
    var amPm = h > 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m} ${amPm}`;
  },
  toTimeString12Hour: function (d) {
    var h = d.getHours();
    var m = d.getMinutes();
    var s = d.getSeconds();
    var amPm = h > 12 ? "PM" : "AM";
    h = h > 12 ? h - 12 : h;
    return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${
      s < 10 ? "0" + s : s
    } ${amPm}`;
  },
  toShortDayTimeString: function (d) {
    return `${this.toShortDateString(d)} ${this.toShortTimeString(d)}`;
  },
  toShortDayTimeString12Hour: function (d) {
    return `${this.toShortDateString(d)} ${this.toShortTimeString12Hour(d)}`;
  },

  sameDay: function (first, second) {
    return (
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate()
    );
  },

  getTimeAgo: function (timestamp) {
    const currentTime = new Date();
    const givenTime = new Date(timestamp);
    const timeDifference = currentTime - givenTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    if (minutes < 1) {
      return seconds + " seconds ago";
    } else if (minutes < 60) {
      return minutes + (minutes == 1 ? " minute ago" : " minutes ago");
    } else if (hours < 24) {
      return hours + (hours == 1 ? " hour ago" : " hours ago");
    } else if (days < 3) {
      return days + (days == 1 ? " day ago" : " days ago");
    } else {
      return this.toShortDateString(givenTime);
    }
  },

  parseShortDateStringTimeToday: function (dateStr, use12Hour) {
    let dt = new Date(dateStr);
    if (this.sameDay(dt, new Date())) {
      return use12Hour
        ? this.toShortTimeString12Hour(dt)
        : this.toShortTimeString(dt);
    } else {
      return this.toShortDateString(dt);
    }
  },
  parseShortDateString: function (dateStr) {
    return this.toShortDateString(new Date(dateStr));
  },
  toShortDateString: function (d) {
    var m = d.getMonth() + 1;
    var day = d.getDate();
    return `${m < 10 ? "0" + m : m}/${
      day < 10 ? "0" + day : day
    }/${d.getFullYear()}`;
  },
  toIsoStringLocal: function (date) {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? "+" : "-",
      pad = function (num) {
        return (num < 10 ? "0" : "") + num;
      };

    return (
      date.getFullYear() +
      "-" +
      pad(date.getMonth() + 1) +
      "-" +
      pad(date.getDate()) +
      "T" +
      pad(date.getHours()) +
      ":" +
      pad(date.getMinutes()) +
      ":" +
      pad(date.getSeconds()) +
      dif +
      pad(Math.floor(Math.abs(tzo) / 60)) +
      ":" +
      pad(Math.abs(tzo) % 60)
    );
  },

  format: function (dt, formatStr, options) {
    var date = parseISO(dt);
    //https://date-fns.org/v2.29.3/docs/format
    return formatFns(date, formatStr, options);
  },

  toYYYMMDD: function (dt) {
    var m = dt.getMonth() + 1;
    var d = dt.getDate();
    var year = dt.getFullYear();
    var month = m < 10 ? "0" + m : m;
    var day = d < 10 ? "0" + d : d;

    return `${year}-${month}-${day}`;
  },
};

const Guid = {
  newGuid: function () {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  },
};
export { DateFormatter, TrackFormatter, Guid, UriParser };

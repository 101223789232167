import { defineStore } from "pinia";
import { TripoliService } from "@/services/tripoli.service";
import { GroupService } from "@/services/group.service";
import { ref, computed, watch, nextTick } from "vue";
import { SessionDetailsDTO } from "@/types/session-details-dto";
import { SessionGroup } from "@/types/session-group";
import { SessionWithLocationDetails } from "@/types/session-with-location-details";
import { GroupDetails } from "@/types/group-details";
import { useAuthStore } from "./auth.store";
import { refresh } from "ionicons/icons";
import { SessionService } from "@/services/session.service";
export const useGroupStore = defineStore("GroupStore", () => {
  const authStore = useAuthStore();
  const authenticated = computed(() => authStore.authenticated);
  const userGroupList = ref([] as any);
  const groupListLoaded = ref(false);
  const activeGroupId = ref(0);
  const groupDetail = ref({
    group: {
      id: 0,
      name: "",
      city: "",
      state: "",
      zip: "",
      userId: 0,
      timeZoneId: "",
      messageChannelId: 0,
      imageAssetData: "",
    } as SessionGroup,
    callerIsAdmin: false,
    callerIsMember: false,
    callerIsApproved: false,
    pastSessions: [] as SessionWithLocationDetails[],
  } as GroupDetails);
  const activeRosterId = ref(0);
  const activeRoster = ref({} as any);
  const session = ref({} as SessionDetailsDTO);
  const activeSessionId = ref(0);
  const activeRosterName = ref("");
  const sessionGroupId = computed(() => activeGroupId.value);
  const groupDetails = computed(() => groupDetail.value);
  const userGroups = computed(() => userGroupList.value);
  const sessionDetails = computed(() => session.value);
  const rosterId = computed(() => activeRosterId.value);
  const sessionId = computed(() => activeSessionId.value);
  const rosterName = computed(() => activeRosterName.value);
  const roster = computed(() => activeRoster.value);
  const setActiveRoster = (newValue: any) => {
    activeRoster.value = newValue;
  };
  const setActiveRosterId = (newValue: any) => {
    activeRosterId.value = newValue;
  };

  watch(authenticated, (newValue) => {
    if (newValue) {
      // if we have a group id, reload the group
      if (groupDetail.value && groupDetail.value.group.id > 0) {
        loadGroup(groupDetail.value.group.id);
      }
      // if we have a session id, reload the session
      if (
        session.value &&
        session.value.session &&
        session.value.session.id > 0
      )
        refreshSessionDetails(session.value.session.id);
    }
  });

  const refreshSessionDetails = async (sessionId: any) => {
    if (sessionId == 0) return;
    return new Promise((resolve, reject) => {
      SessionService.sessionDetails(sessionId)
        .then((result) => {
          if (result) {
            session.value = result;
          }
          resolve(result);
        })
        .catch((err) => reject(err.message));
    });
  };

  const loadGroup = async (sessionGroupId: any) => {
    return new Promise((resolve, reject) => {
      TripoliService.loadGroup(sessionGroupId)
        .then((res) => {
          if (typeof res == "undefined") {
            reject("No group found");
            return;
          }
          groupDetail.value = res;
          res.group.id = sessionGroupId; // force set the group id
          resolve(res);
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  };

  const ensureGroupLoaded = async (groupId: any) => {
    if (groupId.value != groupId) {
      await loadGroup(groupId);
    }
  };

  return {
    sessionGroupId,
    sessionDetails,
    groupDetails,
    userGroups,
    rosterId,
    sessionId,
    rosterName,
    roster,
    setActiveRoster,
    setActiveRosterId,
    refreshSessionDetails,
    ensureGroupLoaded,
    groupListLoaded,
    forceReloadGroup: async (groupId: any) => {
      TripoliService.clearGroupDetailCache(groupId);
      await loadGroup(groupId);
    },
    loadUserGroups: async () => {
      return new Promise((resolve, reject) => {
        GroupService.loadGroupsForUser()
          .then((res: any) => {
            userGroupList.value = res.payload;
            groupListLoaded.value = true;
            resolve(res.payload);
          })
          .catch((err) => {
            reject(err.message);
          });
      });
    },
    loadGroup,
  };
});


import { defineComponent, ref, computed } from "vue";
import UserAvatarVuetify from "../user/UserAvatarVuetify.vue";
import { useRouter } from "vue-router";
export default defineComponent({
    components: {
        UserAvatarVuetify
    },
    props: ["item"],
    emits: ["close"],
    setup(props, context) {
        const show = ref(true);
        const route = useRouter();
        const user = computed(() => props.item.fromUser);
        const showNotification = () => {
            route.push(props.item.feedItem.link);
            close();
        }
        const close = () => {
            context.emit("close");
        }
        return {
            show,
            user,
            showNotification,
            close,
        }

    },
});


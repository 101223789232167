import { useTheme } from "vuetify";
import { ref } from "vue";
import { useMediaQuery } from "@vueuse/core";
export const useThemeSelector = () => {
  const hasDarkModeSetting = localStorage.getItem("DARKMODE");
  const isPreferredDark = useMediaQuery("(prefers-color-scheme: dark)");
  const darkMode = ref(isPreferredDark.value);

  if (hasDarkModeSetting && hasDarkModeSetting == "TRUE") {
    darkMode.value = true;
  }
  const theme = useTheme();
  const toggleDarkMode = () => {
    setDarkMode(!darkMode.value);
  };
  const setDarkMode = (value: boolean) => {
    darkMode.value = value;
    theme.global.name.value = darkMode.value ? "dark" : "light";
    if (darkMode.value) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
    localStorage.setItem("DARKMODE", darkMode.value ? "TRUE" : "FALSE");
  };

  const setInitialMode = () => {
    console.log("initialDarkModeSet:" + darkMode.value);
    setDarkMode(darkMode.value);
  };

  return {
    setInitialMode,
    toggleDarkMode,
    setDarkMode,
    darkMode,
  };
};

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faAlbumCollectionCircleUser } from "@fortawesome/pro-solid-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fat } from "@fortawesome/pro-thin-svg-icons";
import DuoTone from "./fa-duotone";
/* add icons to the library */
library.add(fas, far, fab, fal, fat, faAlbumCollectionCircleUser);
DuoTone.init(library);
export default FontAwesomeIcon;

<template>
  <div data-testid="dt-mb-layout" class="dt-mb-layout w-full px-1 md:px-0 lg:w-3/5 mx-auto">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>

</script>
<style>
.desktop-mobile-layout {
  width: 100%;
  max-width: 900px;
  min-height: 100svh;
  margin: auto;
  display: flex;
  flex-direction: column;
}

@media (max-width: 576px) {
  .desktop-mobile-layout {
    width: 100%;
    margin-bottom: 96px;
  }

}

/* md */
@media (min-width: 768px) {}

/* lg */
@media (min-width: 992px) {}

/* xl */
@media (min-width: 1200px) {}
</style>
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import { TokenService } from "@/services/token.service";
import { UserService } from "@/services/user.service";
import { useAuthStore } from "@/store/auth.store";
import { modalController } from "@ionic/vue";
import { eventBus, events } from "@/utils/eventbus";
export const ONBOARDING_KEY = "onboarding-seen";
const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    component: () => import("../views/HomePage.vue"),
    meta: {
      public: true,
    },
  },

  {
    path: "/sign-up",
    component: () => import("../views/SignUp.vue"),
    meta: {
      public: true,
    },
  },

  {
    path: "/sign-in",
    redirect() {
      return { path: "/" };
    },
    meta: {
      public: true,
    },
  },
  {
    path: "/forgot-password",
    component: () => import("../views/ForgotPassword.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/group/:id",
    component: () => import("../views/SessionGroup.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/group/:groupId/feed/:feedId/message/:id",
    component: () => import("../views/MessageThread.vue"),
    meta: {
      public: false,
    },
  },

  {
    path: "/session/:id",
    component: () => import("@/views/session/SessionPage.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/session/signup/:id",
    component: () => import("@/views/session/RegistrationPage.vue"),
    meta: {
      public: true,
    },
  },

  {
    path: "/admin/session/print/:id",
    component: () => import("@/views/admin/PrintSetList.vue"),
    meta: {
      public: false,
    },
  },
  {
    path: "/session-song",
    component: () => import("@/views/session/SessionSong.vue"),
    meta: {
      public: false,
    },
  },
  {
    path: "/profile/:id",
    component: () => import("../views/UserProfile.vue"),
    meta: {
      public: false,
    },
  },
  {
    path: "/profile/",
    component: () => import("../views/UserProfile.vue"),
    meta: {
      public: false,
    },
  },
  {
    path: "/admin/dashboard/:id",
    component: () => import("../views/admin/AdminDashboard.vue"),
    meta: {
      public: false,
    },
  },
  {
    path: "/admin/edit-group",
    component: () => import("../views/admin/GroupEditor.vue"),
    meta: {
      public: false,
      customSideMenu: true,
    },
  },

  {
    path: "/admin/group-users/:id",
    component: () => import("../views/admin/GroupUserList.vue"),
    meta: {
      public: false,
      customSideMenu: true,
    },
  },
  {
    path: "/admin/group-messages/:id",
    component: () => import("../views/admin/GroupMessageList.vue"),
    meta: {
      public: false,
      customSideMenu: true,
    },
  },
  {
    path: "/admin/add-session",
    component: () => import("../views/admin/AddOrEditSession.vue"),
    meta: {
      public: false,
      customSideMenu: true,
    },
  },

  {
    path: "/admin/view-session",
    component: () => import("../views/admin/ViewSession.vue"),
    meta: {
      public: false,
      customSideMenu: true,
    },
  },
  {
    path: "/admin/view-rosters",
    component: () => import("../views/admin/ViewRosterList.vue"),
    meta: {
      public: false,
      customSideMenu: true,
    },
  },

  {
    path: "/admin/session-song-slot",
    component: () => import("../views/admin/ViewSongSlot.vue"),
    meta: {
      public: false,
      customSideMenu: true,
    },
  },
  {
    path: "/admin/edit-session-songs",
    component: () => import("../views/admin/EditSongList.vue"),
    meta: {
      public: false,
    },
  },
  {
    path: "/dm",
    component: () => import("../views/DirectMessages.vue"),
    meta: {
      public: false,
    },
  },
  {
    path: "/groups",
    component: () => import("../views/GroupList.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/notifications",
    component: () => import("../views/NotificationView.vue"),
    meta: {
      public: false,
    },
  },
  {
    path: "/admin/view-roster",
    component: () => import("../views/admin/ViewRoster.vue"),
    meta: {
      public: false,
      closeModal: true,
      customSideMenu: true,
    },
  },
  {
    path: "/what-is-soundhaus",
    component: () => import("@/views/WhatIsSH.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/upcoming",
    component: () => import("@/views/UpcomingSessions.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/:pathMatch(.*)",
    component: () => import("../views/404NotFound.vue"),
    meta: {
      public: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //history:createWebHashHistory(process.env.BASE_URL),
  //history: createMemoryHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (from.meta.closeModal) {
    modalController.dismiss("nav");
  }

  if (to.path.toLowerCase() == "/sign-in") {
    return next({
      path: "/",
      query: { redirect: to.fullPath, signOut: "true" },
    });
  }

  // login guard
  const isPublic = to.matched.some((record) => record.meta.public);
  const loggedIn = !!(await TokenService.getToken());
  if (!isPublic && !loggedIn) {
    return next({
      path: "/",
      query: { redirect: to.fullPath, signOut: "true" },
    });
  }
  return next();
});

router.afterEach(async (to, from) => {
  const authStore = useAuthStore();

  const loggedIn = !!(await TokenService.getToken());
  if (loggedIn && authStore.authChecked) {
    await UserService.sendOnlineStatus(to.path.toLocaleLowerCase());
    authStore.refreshNotifications();
  }
  if (from.meta.customSideMenu && !to.meta.customSideMenu) {
    //  pageStore.setDefaultMenu();
    eventBus.$emit(events.SET_DEFAULT_MENU);
  }
});
export default router;

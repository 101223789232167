import { UserProfileMini } from "@/types/user-profile-mini";
import cookieUtil from "@/utils/cookie-util";
const TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";
const REFRESH_KEY = "refresh_at";
const PROFILE_KEY = "profile_key";
const GUEST_ID_KEY = "guest_id";
const TokenService = {
  save(data: any) {
    this.saveToken(data.token);
    this.saveRefreshToken(data.refreshToken, data.refreshAt);
    this.saveProfile(data.profile);
  },
  getGuestToken() {
    let guestToken = localStorage.getItem(GUEST_ID_KEY);
    if (!guestToken) {
      guestToken = cookieUtil.getCookie(GUEST_ID_KEY);
    }
    return guestToken;
  },
  saveGuestToken(guestToken: string) {
    // store the guest token in local storage
    localStorage.setItem(GUEST_ID_KEY, guestToken);
    // store the guest token in a cookie
    cookieUtil.setCookie(GUEST_ID_KEY, guestToken, 365);
  },
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },
  getRefreshAt() {
    return localStorage.getItem(REFRESH_KEY);
  },
  getRefreshKey() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },

  saveToken(accessToken: string) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },

  getProfile(): UserProfileMini | null {
    const ret = localStorage.getItem(PROFILE_KEY);
    if (!ret || ret == "undefined") return null;
    return JSON.parse(ret) as UserProfileMini;
  },

  saveProfile(profile: any) {
    localStorage.setItem(PROFILE_KEY, JSON.stringify(profile));
  },

  removeAll() {
    this.removeToken();
    this.removeRefreshToken();
    this.removeProfile();
  },
  removeProfile() {
    localStorage.removeItem(PROFILE_KEY);
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },

  saveRefreshToken(refreshToken: string, refreshAt: any) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    localStorage.setItem(REFRESH_KEY, refreshAt);
  },

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(REFRESH_KEY);
  },
  getGuestId() {
    return localStorage.getItem(GUEST_ID_KEY);
  },
  saveGuestId(guestId: string) {
    localStorage.setItem(GUEST_ID_KEY, guestId);
  },
};
export { TokenService };


import { defineComponent, ref } from "vue";
import { defaultProfileUrl } from "@/utils/defaults";
import { eventBus, events } from "@/utils/eventbus";
import AppIcon from "@/components/icon/AppIcon.vue";

import {
  IonPage,
  IonContent,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonAvatar,
  useIonRouter,
  popoverController,
  IonRefresher,
  IonRefresherContent,
  IonBadge,
  IonMenuButton,
} from "@ionic/vue";

import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/store/auth.store";
import { computed, nextTick, watch } from "vue";
import AvatarProfileMenu from "@/components/AvatarProfileMenu.vue";
import UnreadMessages from "@/components/messaging/UnreadMessages.vue";
import { usePageStore } from "@/store/page.store";
import { useDisplay } from 'vuetify'
import { useAuthNav } from "@/composables/useAuthNav";
export default defineComponent({
  components: {
    IonPage,
    IonButtons,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonAvatar,
    IonRefresher,
    IonRefresherContent,
    IonBadge,
    IonMenuButton,
    AppIcon
  },
  props: {
    pageTitle: {
      type: String, required: false
    },
    showBackButton: {
      type: Boolean, required: false, default: false,
    },
    homeLink: {
      type: String, required: false
    },
    onPullRefresh: {
      type: Function,
      required: false,
    },
    showGuestNav: {
      type: Boolean,
      required: false,
      default: false,
    },
    scrollPage: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  emits: ["scroll", "scrollEnd"],
  setup(props, context) {
    const selectedIndex = ref(0);
    const ionContent = ref();
    const loaded = ref(false);
    const authStore = useAuthStore();
    const ionRouter = useIonRouter();
    const pageStore = usePageStore();
    const display = useDisplay();
    const route = useRoute();
    const router = useRouter();

    const showAppNavigation = computed(() => authStore.authenticated && !display.lgAndUp.value);
    const notifications = computed(() => authStore.notifications);
    const notificationCount = computed(() => authStore.unreadNotifications || 0);
    const profile = computed(() => authStore.profile);
    const authNav = useAuthNav();
    const toggleNavDrawer = () => {
      pageStore.toggleLeftDrawer();
    }

    const pageTitle = computed(() => {
      return props.pageTitle || "";
    });
    watch(pageTitle, (newValue) => {
      nextTick(() => {
        document.title = newValue ? "SoundHaus -" + newValue : "SoundHaus";
      })
    }, { immediate: true });
    const signUp = () => {
      authNav.navToSignUp(route.fullPath);
    }
    const signIn = () => {
      authNav.navToSignIn(route.fullPath);
    }

    const showGuestNavigation = computed(() => {
      return false && !authStore.authenticated && props.showGuestNav;
    })
    const contentClass = computed(() => {
      return showAppNavigation.value ? "app-navigation-visible" : "";
    })
    eventBus.$on(events.SCROLL_TO, (x: number, y: number, duration: number) => {
      ionContent.value?.$el.scrollToPoint(x, y, duration);
    })
    eventBus.$on(events.SCROLL_TO_TOP, () => {
      ionContent.value?.$el.scrollToTop();
    })






    const onScrollEnd = async (evt: any) => {
      const target = evt.target.scrollEl;
      context.emit("scrollEnd", target);
      eventBus.$emit(events.SCROLL_END, target);
    }
    const showNotifications = async (ev: Event) => {
      const popover = await popoverController.create({
        component: UnreadMessages,
        event: ev,
        dismissOnSelect: true,
        componentProps: { items: notifications.value },
        cssClass: 'notification-popover'
      });
      await popover.present();

      await popover.onDidDismiss();
    }

    const showAvatarMenu = async (ev: Event) => {
      const popover = await popoverController.create({
        component: AvatarProfileMenu,
        event: ev,
        dismissOnSelect: true,
      });
      await popover.present();

      await popover.onDidDismiss();
    }
    const getHomeLink = () => {
      if (props.homeLink) return props.homeLink;
      return "/";
    };

    const nav = (url: string) => {
      ionRouter.navigate(url, "forward");
    }


    const handleRefresh = async (event: any) => {
      if (props.onPullRefresh) {
        props.onPullRefresh(event);
      } else {
        event.target.complete();
      }
    };

    const goHome = () => {
      ionRouter.replace(getHomeLink());
    };

    return {
      goHome,
      showAvatarMenu,
      handleRefresh,
      contentClass,
      profile,
      selectedIndex,
      route,
      router,
      defaultProfileUrl,
      notificationCount,
      showNotifications,
      notifications,
      showBack: props.showBackButton ? props.showBackButton : true,
      isSelected: (url: string) => (url === route.path ? "selected" : ""),
      ionContent,
      loaded,
      showAppNavigation,
      toggleNavDrawer,
      nav,
      onScrollEnd,
      signUp,
      signIn,
      showGuestNavigation,
      logout: () => {
        authNav.navToSignIn("/");
      },
    };
  },
});

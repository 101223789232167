import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_TwicImg = _resolveComponent("TwicImg")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_v_chip = _resolveComponent("v-chip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.menu.icon && !_ctx.menu.image)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          icon: _ctx.menu.icon,
          class: "fa-inline-icon menu-icon"
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    (_ctx.menu.image)
      ? (_openBlock(), _createBlock(_component_ion_avatar, {
          key: 1,
          class: "menu-icon"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TwicImg, {
              ratio: "1/1",
              resize: "28",
              src: _ctx.menu.image,
              alt: "group logo"
            }, null, 8, ["src"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.menu.notificationCount)
      ? (_openBlock(), _createBlock(_component_v_chip, {
          key: 2,
          size: "x-small",
          class: "menu-notification notification-chip"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.menu.notificationCount), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}


import { defineComponent, ref, computed } from "vue";
import MenuIcon from "./MenuIcon.vue";
import { useRouter } from "vue-router";
import { useThemeSelector } from "@/composables/useThemeSelector";
export default defineComponent({
    components: {
        MenuIcon,
    },
    props: ["menu", "className"],
    emits: ["itemClick"],
    setup(props, context) {
        const theme = useThemeSelector();
        const opened = ref("");
        const menuClick = async (item: any) => {
            context.emit("itemClick", item);
        }
        const colorForMode = computed(() => {
            return theme.darkMode.value ? "secondary" : "primary"
        });
        const router = useRouter();
        const selected = (menu: any) => {
            if (menu.matchPartialPath && menu.link) {
                return router.currentRoute.value.fullPath.startsWith(menu.link);
            }
            if ((menu.link && menu.link == router.currentRoute.value.path) || (menu.link == router.currentRoute.value.fullPath)) {
                return true;
            }
            return false;
        }
        return {
            selected,
            menuClick,
            opened,
            colorForMode,
        };
    },
});
